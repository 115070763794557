import { Injectable, signal } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { Company, CompanyWithStores, Store } from '../../../login/models/company-store.model'
import { environment } from '../../../../environments/environment'

type TStorageLocal = {
  key: string
  value: unknown
}

export interface IStorageRepository<T> {
  save?(data: T): void
}

export interface IStorageLocalStorage extends IStorageRepository<TStorageLocal> {
  setItem(data: TStorageLocal): void
  getItem(key: string): string
  removeItem(key: string): void
}

@Injectable({
  providedIn: 'root',
})
export class DataService implements IStorageLocalStorage {
  mainObjectName = 'appData'
  private key: string

  constructor() {
    this.key = environment.KEY_STORAGE
  }

  // CLEANABLE SIGNALS //
  signalUserLocations = signal<CompanyWithStores[]>([])
  signalUserCompanies = signal<Company[]>([])
  signalCurrentCompanyStores = signal<Store[]>([])
  signalLastCompany = signal<Company>({ companyId: 0, companyNumericCode: 0, companyName: '' })
  signalLastStore = signal<Store>({ storeId: 0, storeName: '', storeCode: 0, storeTimezone: 99 })
  signalFirstName = signal<string>('')
  signalLastName = signal<string>('')
  signalRole = signal<string>('')
  signalStoreTimezone = signal<string>('')
  signalPendingSafeClosure = signal<boolean>(false)
  signalPendingEvents = signal<boolean>(false)

  // NON-CLEANABLE SIGNALS //
  signalUsername = signal<string>('')
  signalLastUrl = signal<string>('')

  clearCleanableSignals(): void {
    this.signalUserCompanies.set([])
    this.signalCurrentCompanyStores.set([])
    this.signalLastCompany.set({ companyId: 0, companyNumericCode: 0, companyName: '' })
    this.signalLastStore.set({ storeId: 0, storeName: '', storeCode: 0, storeTimezone: 99 })
    this.signalFirstName.set('')
    this.signalLastName.set('')
    this.signalRole.set('')
    this.signalStoreTimezone.set('')
    this.signalPendingSafeClosure.set(false)
  }

  clearAllSignals(): void {
    this.signalUserCompanies.set([])
    this.signalCurrentCompanyStores.set([])
    this.signalLastCompany.set({ companyId: 0, companyNumericCode: 0, companyName: '' })
    this.signalLastStore.set({ storeId: 0, storeName: '', storeCode: 0, storeTimezone: 99 })
    this.signalFirstName.set('')
    this.signalLastName.set('')
    this.signalRole.set('')

    this.signalUserLocations.set([])
    this.signalUsername.set('')
    this.signalLastUrl.set('')
    this.signalStoreTimezone.set('')
    this.signalPendingSafeClosure.set(false)
  }

  updateSignals(): void {
    const encryptedData = localStorage.getItem(this.mainObjectName)
    if (encryptedData) {
      const appData = JSON.parse(this.decrypt(encryptedData))
      this.signalUserLocations.set(appData.userLocations || [])
      this.signalUserCompanies.set(appData.userCompanies || [])
      this.signalCurrentCompanyStores.set(appData.currentCompanyStores || [])
      this.signalLastCompany.set(appData.lastCompany || { companyId: 0, companyNumericCode: 0, companyName: '' })
      this.signalLastStore.set(appData.lastStore || { storeId: 0, storeName: '', storeCode: 0, storeTimezone: 99 })
      this.signalUsername.set(appData.username || '')
      this.signalFirstName.set(appData.firstName || '')
      this.signalLastName.set(appData.lastName || '')
      this.signalRole.set(appData.role || '')
      this.signalStoreTimezone.set(appData.storeTimezone || '')
    }
  }

  clearLocalData(): void {
    localStorage.removeItem(this.mainObjectName)
  }

  // METHODS FOR LOCAL STORAGE //

  setItem(item: TStorageLocal): void {
    const encryptedData = localStorage.getItem(this.mainObjectName)
    if (encryptedData) {
      const data = JSON.parse(this.decrypt(encryptedData))
      data[item.key] = item.value
      localStorage.setItem(this.mainObjectName, this.encrypt(JSON.stringify(data)))
    } else {
      const data = { [item.key]: item.value }
      localStorage.setItem(this.mainObjectName, this.encrypt(JSON.stringify(data)))
    }
  }

  getItem(key: string): string {
    const encryptedData = localStorage.getItem(this.mainObjectName)

    if (encryptedData) {
      const data = JSON.parse(this.decrypt(encryptedData))
      return data[key]
    } else {
      return ''
    }
  }

  removeItem(key: string): void {
    const encryptedData = localStorage.getItem(this.mainObjectName)
    if (encryptedData) {
      const data = JSON.parse(this.decrypt(encryptedData))
      delete data[key]
      localStorage.setItem(this.mainObjectName, this.encrypt(JSON.stringify(data)))
    }
  }

  private encrypt(text: string): string {
    return environment.envName !== 'local' ? CryptoJS.AES.encrypt(text, this.key).toString() : text
  }

  private decrypt(text: string): string {
    return environment.envName !== 'local' ? CryptoJS.AES.decrypt(text, this.key).toString(CryptoJS.enc.Utf8) : text
  }
}
